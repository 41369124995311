import './overview.css'
import transition from '../../transition'

const overview = () => {
  return (
    <div>
      overview
    </div>
  )
}

export default transition(overview);
