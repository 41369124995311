import './pricing.css'
import transition from '../../transition'

const pricing = () => {
  return (
    <div>
      pricing
    </div>
  )
}

export default transition(pricing);
