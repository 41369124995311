import './about.css'
import transition from '../../transition'




const About = () => {
  return (
    <div>
      About
    </div>
  )
}

export default transition(About);
