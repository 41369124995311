import './feature.css'
import transition from '../../transition'

const feature = () => {
  return (
    <div>
      feature
    </div>
  )
}

export default transition(feature);
